import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {
	BrowserRouter as Router,
} from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';

import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCvNUucOHQ1L23WxcUhPhtJgWULtlTmnNs",
  authDomain: "shpsec-telescope.firebaseapp.com",
  databaseURL: "https://shpsec-telescope.firebaseio.com",
  projectId: "shpsec-telescope",
  storageBucket: "shpsec-telescope.appspot.com",
  messagingSenderId: "757197312812",
  appId: "1:757197312812:web:9ef3755d1086377ad51289"
};
firebase.initializeApp(firebaseConfig);




ReactDOM.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
