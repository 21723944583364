import React, {useState, useEffect} from 'react';

import {
	Form, Button, Container, Col, Row, Table,
} from "react-bootstrap";


import {
    useParams
} from "react-router-dom";

import firebase from 'firebase/app';

function CompanyView(props) {
	const {token} = useParams();

    const [companyName, setCompanyName] = useState("");
    const [companyID, setCompanyID] = useState("");
    const [files, setFiles] = useState([]);
    const [markedForDeletion, setMarkedForDeletion] = useState(null);
    const [notifyOnFileUpload, setNotifyOnFileUpload] = useState(null);

	useEffect(() => {
		const tokenRef = firebase.database().ref().child("filedrop/tokens/" + token);
		tokenRef.on("value", snap => {
			let data = snap.val();
			setCompanyName(data?.companyName);
			setCompanyID(data?.companyID);
            setMarkedForDeletion(data?.markedForDeletion);
            setNotifyOnFileUpload(data?.notifyOnFileUpload);
		});
    }, [token]);

    
	useEffect(() => {
		const filesRef = firebase.database().ref().child("filedrop/tokens/" + token + "/files");
		filesRef.on("value", snap => {
			let data = snap.val();
            if(data != null) {
                let arr = [];
                Object.keys(data).forEach((fileID, idx, key) => {
                    arr.push({
                        id: fileID,
                        data: data[fileID]
                    });
                });
                arr.sort((a,b) => {
                    return a.id > b.id ? -1 : 1;
                });
                setFiles(arr);
            }
		});
    }, [token]);

    useEffect(() => {
        if (markedForDeletion != null) {
            let path = "filedrop/tokens/" + token;
            firebase.database().ref(path).update({
                markedForDeletion: markedForDeletion,
            });
        }
    }, [token, markedForDeletion]);

    useEffect(() => {
        if (notifyOnFileUpload != null) {
            let path = "filedrop/tokens/" + token;
            firebase.database().ref(path).update({
                notifyOnFileUpload: notifyOnFileUpload,
            });
        }
    }, [token, notifyOnFileUpload]);

    const updateCompany = (e) => {
        if (e !== undefined && e !== null) {
            e.preventDefault();
        }
        
        let tokenData = {
            companyName: companyName,
            companyID: companyID,
            notifyOnFileUpload: notifyOnFileUpload,
        };
        
        let path = "filedrop/tokens/" + token;
        let tokenRef = firebase.database().ref(path);
        tokenRef.update(tokenData);
    };

	const fileRows = files.map((val, idx, arr) => {
		var f = val.data;
        const datetimeUTC = (new Date(f.datetime)).toUTCString();
		return (
			<tr key={val.id}  >
              <td>{datetimeUTC}</td>
			  <td>{f["filename"]}</td>
			  <td>{f["paths"]["scrubbed"]}</td>
			</tr>
		);
	});

    const confirmDeletion = (e) => {
        if(window.confirm("Are you sure you want to delete this token and its history?")) {
            setMarkedForDeletion(true);
            window.alert("This token and its history will be deleted shortly unless unmarked.");
        }
    };

    const deleteButton = markedForDeletion ? (
        <Button onClick={e => setMarkedForDeletion(false)} variant="outline-danger">
          Unmark for Deletion
        </Button>
    ) : (
        <Button onClick={confirmDeletion} variant="danger">Mark for Deletion</Button>
    );

    const notifyOnFileUploadButton = notifyOnFileUpload ? (
        <Button onClick={e => setNotifyOnFileUpload(false)} variant="warning">Stop notifying on file upload</Button>
    ) : (
        <Button onClick={e => setNotifyOnFileUpload(true)} variant="outline-warning">Notify on file upload</Button>
    );

    
    return (
        <>
		  <Container className="space-on-top">
            <Row><Col>Token: <span className="mono-font-plz">{token}</span></Col></Row>
            <Form>
              <Form.Row>
                <Col xs={5}>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} />
                  </Form.Group>
                </Col>
                <Col xs={5}>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Leave blank or use ARS customer ID" value={companyID} onChange={e => setCompanyID(e.target.value)}/>
                  </Form.Group>
                </Col>
                <Col xs={2}>
                  <Button variant="primary" onClick={updateCompany}>Update Record</Button>
                </Col>
              </Form.Row>
            </Form>
            {deleteButton}{' '}
            {notifyOnFileUploadButton}
          </Container>

          <Container className="mt-2 col-md-12">
		    <Row>
			  <Col>
			    <Table bordered hover size="sm">
				  <thead>
				    <tr>
					  <th md={2}>Datetime</th>
					  <th>Original file name</th>
					  <th>gsutil link</th>
				    </tr>
				  </thead>
				  <tbody>
				    {fileRows}
				  </tbody>
			    </Table>
			  </Col>
		    </Row>
		  </Container>
        </>
    );
}

export default CompanyView;

