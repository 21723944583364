import React, {useState, useEffect} from 'react';

import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';



import {
    Container, Form, Button, Row, Col,
} from "react-bootstrap";

import Dropzone from 'react-dropzone';


function UploadView (props) {

    const [token, setToken] = useState("");
    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [uploadedFileNames, setUploadedFileNames] = useState([]);
    const [totalUploadProgress, setTotalUploadProgress] = useState(0);

    useEffect(() => {
        setToken(props.currentUser?.uid);
    }, [props.currentUser]);

    const loginAndUpload = (e) => {
        e.preventDefault();

        setIsAuthenticating(true);

        if(token === undefined || token === null || token === "") {
            alert("Token must be set.");
            return;
        }

        var getSignedToken  = firebase.functions().httpsCallable('getSignedToken');
        getSignedToken({token: token}).then((result) => {
            var signedToken = result.data.signedToken;

            firebase.auth().signInWithCustomToken(signedToken).then(() =>{
                uploadFiles(token);
            }).catch((err) => {
                console.group("Could not sign in.", err);
            });
        }).catch((err) => {
            alert("Token invalid.");
            console.group("Token invalid.", err);
        });;
    };

    const uploadFiles = (token) => {

        var storageRef = firebase.storage().ref();
        let uploadTasks = [];
        let progressArr = new Array(files.length).fill(0);

        files.forEach((file, i, arr) => {

            // Create the file metadata
            var metadata = {
                token: token
            };
            
            // Upload file and metadata to the object 'images/mountains.jpg'
            var uploadTask = storageRef.child('filedrop/sandbox/' + token + '/' + file.name)
                .put(file, metadata);
            uploadTasks.push(uploadTask);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
                firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and
                    // the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    progressArr[i] = progress;
                    let sum = 0;
                    for(let j=0; j<arr.length; j++) {
                        sum += Math.trunc(progressArr[j]);
                    }
                    let total = sum /= arr.length;
                    setTotalUploadProgress(total);
                    
                    switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        break;
                    default:
                        break;
                    }
                }, (error) => {

                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                    case 'storage/unauthorized':
                        console.log("Not authorized:", error);
                        // User doesn't have permission to access the object
                        break;

                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    default:
                        break;
                    }
                }, () => {
                    // Upload completed successfully, now we can get the download URL
                    setUploadedFileNames((prevArr) => [...prevArr, file.name]);
                    
                });
        });

        setIsAuthenticating(false);
        setIsUploading(true);
        setTotalUploadProgress(0);
        Promise.all(uploadTasks).then((uploadTasks) => {
            setTotalUploadProgress(0);
            setIsUploading(false);
            setFiles([]);
        });
    };

    const tokenChange = (e) => {
        setToken(e.target.value);
    };

    const updateFiles = (files) => {
        setFiles(files);
    };

    const filesList = files.map((file, i, arr) => {
        return (
            <li key={file.name}>{file.name}</li>
        );
    });

    const aside = files.length > 0 ? (
        <aside>
          <p>Files prepared for upload:</p>
          <ul>{filesList}</ul>
        </aside>
    ) : null;

    const mainView = !isAuthenticating && !isUploading ? (
        <>
          <Dropzone onDrop={acceptedFiles => updateFiles(acceptedFiles)}>
            {({getRootProps, getInputProps}) => (
                <section className="container file-container">
                  <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <div>Drag 'n' drop some files here, or click to select files</div>
                  </div>
                  {aside}
                </section>
            )}
          </Dropzone>

          <Button variant="primary" type="submit" onClick={loginAndUpload}>Submit</Button>
        </>
    ) : (
        isAuthenticating ? (
            <p>Authenticating ...</p>
        ) : (
            isUploading ? (
                <p>Uploading...{totalUploadProgress}%</p>
            ) : null
        )
    );

    const uploadedListView = uploadedFileNames.length > 0 ? (
        uploadedFileNames.map((filename,i,arr) => {
            return (
                <li key={i}>{filename}</li>
            );
        })
    ) : null;

    const uploadedFiles = uploadedFileNames.length > 0 ? (
        <aside>
          <p>Files Uploaded:</p>
          <ul>
            {uploadedListView}
          </ul>
        </aside>
    ) : null;

    return (
        <Container className="space-on-top">
          <Row >
            <Col md="6">
              <Form>
                <Form.Group>
                  <Form.Control type="text" placeholder="customer token" defaultValue={props.currentUser?.uid} onChange={tokenChange} />
                  <Form.Text className="text-muted">
                    Token previously given to you.
                  </Form.Text>
                </Form.Group>

                {mainView}

              </Form>
            </Col>
            <Col md="6">
              {uploadedFiles}
            </Col>
          </Row>
        </Container>
    );
}

export default UploadView;
