import React, { useState, useEffect } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import {
	Container, Table, Row, Col,
} from "react-bootstrap";

import {
    Link,
} from "react-router-dom";


function ManagerView () {
	
	const [tokens, setTokens] = useState({});

	useEffect(() => {
        let mounted = true;
		const tokensRef = firebase.database().ref().child("filedrop/tokens");
		tokensRef.on("value", snap => {
            if(mounted) {
			    setTokens(snap.val());
            }
		});

        return  () => mounted = false;
	}, []);

	const tokenRows = Object.keys(tokens).map((token, idx, key) => {
		var h = tokens[token];
        const markedForDeletionText = h["markedForDeletion"] === true ? (
            <span className="red">Yes</span>
        ) : (
            <span>No</span>
        );
		return (
			<tr key={token}  >
			  <td><Link to={"/manager/" + token}>{h["companyName"]}</Link></td>
			  <td className={"mono-font-plz"} >{h["companyID"]}</td>
			  <td className={"mono-font-plz"} >{token}</td>
			  <td>{h["notifyOnFileUpload"] === true ? "Yes" : "No"}</td>
			  <td>{markedForDeletionText}</td>
			  <td>{h["createdBy"]}</td>
			</tr>
		);
	});

	return (
        <>
		  <Container className="mt-2 col-md-12">
		    <Row>
			  <Col>
			    <Table bordered hover size="sm">
				  <thead>
				    <tr>
					  <th md={2}>Company Name</th>
					  <th md={2}>Company ID</th>
					  <th>Token</th>
					  <th>Notification</th>
					  <th>Marked for Deletion</th>
					  <th>Created by</th>
				    </tr>
				  </thead>
				  <tbody>
				    {tokenRows}
				  </tbody>
			    </Table>
			  </Col>
		    </Row>
		  </Container>
        </>
	);
}

export default ManagerView;
