import React, { useState, useEffect } from 'react';
import "./App.css";

import firebase from 'firebase/app';
import 'firebase/auth';

import {
	Navbar, Nav
} from "react-bootstrap";


import {
	Switch,
	Route,
} from "react-router-dom";

import { useHistory } from 'react-router-dom';


import ManagerView from './Views/ManagerView';
import CompanyView from './Views/CompanyView';
import UploadView from './Views/UploadView';

function App(props) {

    const [currentUser, setCurrentUser] = useState(null);
    let history = useHistory();

    useEffect(() => {
        setupAuthenticationListeners();
    }, []);

	const setupAuthenticationListeners = () => {
		const auth = firebase.auth();
		auth.getRedirectResult().then((result) => {
			if (result.credential) {
				// This gives you a Google Access Token. You can use it to access the Google API.
				// var token = result.credential.accessToken;
			}
			var user = result.user || auth.currentUser;
            setCurrentUser(user || auth.currentUser);
		}).catch(function (error) {
			console.log(error);
		});
	};

	const loginRedirect = () => {
		var provider = new firebase.auth.GoogleAuthProvider();
		firebase.auth().signInWithRedirect(provider);
	};

	const selectedLogout = (e) => {
		firebase.auth().signOut();
        setCurrentUser(null);
        history.push("/");
	};

	const createNewCompany = (e) => {
        if (currentUser !== null){
		    var token = firebase.database().ref().child('filedrop/tokens').push().key;

		    let tokenData = {
			    companyName: "change me",
			    companyID: "",
			    active: true,
                markedForDeletion: false,
                notifyOnFileUpload: true,
                target: "SAFE",
			    createdBy: currentUser.email,
            };

		    let path = "filedrop/tokens/"+token;

		    firebase.database().ref(path).update(tokenData);
        }

	};

    const createCompanyHandle = (e) => {
        e.preventDefault();
        createNewCompany();
    };


	const userEmail = currentUser !== undefined && currentUser !== null ? (
		<Navbar.Text>{currentUser.email}</Navbar.Text>
	) : null;

	const loginLogoutLink = (currentUser !== undefined && currentUser !== null) ? (
		<Nav.Link onClick={selectedLogout}>Logout</Nav.Link>
	) : (
		<Nav.Link onClick={loginRedirect}>Login</Nav.Link>
	);

    return (
		<div className="App">
		  <Navbar bg="dark" variant="dark" expand="lg">
			<Switch>
			  <Route path="/manager">
				<Navbar.Brand href="/"><img className="logo" src="f5-logo-rgb.png" alt="Shape logo"/> | File Drop</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
				  <Nav className="mr-auto">
                    <Route path="/manager/:token">
                      <Nav.Item>
                        <Nav.Link href="/manager">Company List</Nav.Link>
                      </Nav.Item>
                    </Route>
                    <Route exact path="/manager">
                      <Nav.Item>
                        <Nav.Link href="/manager" onClick={createCompanyHandle}>Create Company</Nav.Link>
                      </Nav.Item>
                    </Route>

				  </Nav>

                  <Switch>
                    <Route path="/manager*">
                      {userEmail}
					  {loginLogoutLink}
                    </Route>
                  </Switch>
				</Navbar.Collapse>
			  </Route>
			  <Route>
				<Navbar.Brand href="/"><img className="logo" src="f5-logo-rgb.png" alt="Shape logo"/> | File Drop</Navbar.Brand>
			  </Route>
			</Switch>
		  </Navbar>

		  <Switch>
			<Route exact path="/manager">
			  <ManagerView />
			</Route>

			<Route path="/manager/:token">
			  <CompanyView />
			</Route>

			<Route path="/*">
			  <UploadView currentUser={currentUser} />
			</Route>
		  </Switch>
		</div>
	);
}


export default App;
